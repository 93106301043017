import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/qrcode",
    name: "Qrcode",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Qrcode.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sale",
    name: "Sale",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Sale.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "genqr",
        name: "sale.genqr",
        component: () =>
          import(
            /* webpackChunkName: "SaleGenQrPage" */ "../components/sale/Genqr.vue"
          ),
      },
      {
        path: "preview",
        name: "sale.preview",
        component: () =>
          import(
            /* webpackChunkName: "SalePreviewQrcodePage" */ "../components/sale/PreviewQrcode.vue"
          ),
        props: true,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["login/getAccessBearerToken"]) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters["login/getAccessBearerToken"]) {
      console.log("You are logged in, go to history page...");
      next({ name: "sale.genqr" });
    } else {
      console.log("Visitor Go to login page...");
      next();
    }
  }
});

export default router;

import { createStore } from "vuex";
import login from "./modules/User.js";
import sale from "./modules/Sale.js";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    sale,
  },
});

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueLoading from "vue-loading-overlay";
import VueTimepicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";

// Vue loading overlay style
import "vue-loading-overlay/src/css/index.css";

// tailwindcss style
import "@/assets/style/tailwindcss.css";

/**
 * Axios request interceptors
 */
axios.interceptors.request.use(
  async (config) => {
    const token = await store.getters["login/getAccessBearerToken"];
    // const token = "Bearer FTfVzhbUv118jnFTq-zwvNGLO3s-ogM3";
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error);
  }
);

axios.interceptors.response.use((response) => {
  console.log(response);
  return response;
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueLoading)
  .component("VueTimepicker", VueTimepicker)
  .mount("#app");

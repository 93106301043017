<template>
  <div id="header">
    <div v-if="isLoad" class="grid grid-cols-12 h-28">
      <!-- <div class="md:container md:max-w-screen-sm mr-auto ml-auto text-right"> -->
      <div
        :class="{
          'col-start-1 col-end-13 m-auto w-4/5 sm:w-2/3 md:max-w-xl':
            $route.name !== 'Login',
          'col-start-1 col-end-13 m-auto mb-14 w-4/5 sm:w-2/3 md:max-w-xl':
            $route.name === 'Login',
        }"
      >
        <!-- <img :src="logo" class="col-start-1 col-end-6" /> -->
        <img
          :src="logo"
          class="col-start-2 col-end-11 w-40 transform translate-y-8 sm:col-start-4 sm:col-end-9"
        />
      </div>
      <div
        v-if="$route.name !== 'Login'"
        class="col-start-6 col-end-13 pr-10 text-right text-white"
      >
        <button @click="$store.dispatch('login/destroyAuth')">
          <p v-if="fullText">Logout ( {{ userName }} )</p>
          <p v-else>Logout</p>
        </button>

        <!-- <button @click="$store.dispatch('login/destroyAuth')">
          Logout ( {{ userName }} )
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      logo: require("../assets/logo_letmein.png"),
      userName: "",
      showMenu: false,
      isLoad: false,
      fullText: true,
    };
  },
  async created() {
    this.userName = this.$store.getters["login/getUserName"];
    this.isLoad = true;

    if (screen.width <= 600) {
      this.fullText = false;
    }
    console.log(screen.width);
  },
  method: {
    // setShowMenu() {
    //   this.showMenu = this.$route.name === "Login" ? false : true;
    //   console.log(this.$route.name);
    // },
  },
};
</script>

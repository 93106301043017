import axios from "axios";
var qs = require("qs");

const state = () => ({
  // paramsAppointment: {
  //   first_name: "",
  //   last_name: "",
  //   project_name: "",
  //   contact_matter: "",
  //   mobile: "",
  //   appointment_date: "",
  //   appointment_start: "",
  //   appointment_end: "",
  //   url_qrcode: "",
  //   pin_code: "",
  // },
});

// const getters = {
//   getParamsAppointment(state) {
//     return state.paramsAppointment;
//   },
// };

// const mutations = {
//   setParamsAppointment(state, data) {
//     console.log(data);
//     state.paramsAppointment = {
//       first_name: data.first_name,
//       last_name: data.last_name,
//       project_name: data.project_name.th,
//       contact_matter: data.contact_matter,
//       mobile: data.mobile,
//       appointment_date: data.appointment_date,
//       appointment_start: data.appointment_start,
//       appointment_end: data.appointment_end,
//       url_qrcode: data.url_qrcode,
//       pin_code: data.pin_code,
//     };
//   },
// };

const actions = {
  async actionCreateImg(context, credentials) {
    try {
      const res = await axios.post(
        process.env.VUE_APP_CREATE_APPOINTMENT,
        credentials
      );
      const data = res;
      console.log("Response API GEN Appointment ::", data);

      if (res.status === 200 && res.data.status === 1) {
        return res.data.data;
      } else return false;
    } catch (err) {
      console.error(err);
    }
  },

  async actionCreateQrcode(context, credentials) {
    try {
      const params = qs.stringify(credentials);

      console.log("Params Gen QR ::", params);
      const res = await axios.post(process.env.VUE_APP_SALE_REGISTER, params);
      const data = res;
      console.log("Response API GEN QR CODE ::", data);

      if (res.status === 200 && res.data.status) {
        return res.data.data;
      } else return false;
    } catch (err) {
      console.error(err);
    }
  },

  async actionSaveParamsAppointment(context, credentials) {
    try {
      context.commit("setParamsAppointment", credentials);
      return true;
    } catch (err) {
      console.error(err);
    }
  },

  async actionDownloadQrcode(context, credentials) {
    try {
      const res = await axios({
        url: credentials.imgUrl,
        method: "GET",
        responseType: "blob",
      });
      // await axios({
      //   url: credentials.imgUrl,
      //   method: "GET",
      //   responseType: "blob",
      // }).then((response) => {
      if (res) {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "qrcode.png");
        document.body.appendChild(fileLink);

        fileLink.click();
        const data = {
          status: true,
          message: "Download Appointment Success",
        };
        console.log(data);
        return data;
      }

      // });
    } catch (err) {
      console.error(err);
    }
  },

  async actionGetContactSale() {
    try {
      const res = await axios.get(process.env.VUE_APP_GET_CONTACT_SALE);

      if (res.status === 200 && res.data.status) {
        return res.data.data;
      } else return false;
    } catch (err) {
      console.error(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  // mutations,
  actions,
};

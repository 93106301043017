import axios from "axios";

const state = () => ({
  accessBearerToken: localStorage.getItem("accessBearerToken") || "",
  projectCode: localStorage.getItem("projectCode") || "",
  projectName: localStorage.getItem("projectName") || "",
  userName: localStorage.getItem("userName") || "",
  addressNo: localStorage.getItem("addressNo") || "",
});

const getters = {
  loggedIn(state) {
    return state.accessBearerToken !== "";
  },

  getAccessBearerToken(state) {
    return state.accessBearerToken;
  },

  getProjectCode(state) {
    return state.projectCode;
  },

  getProjectName(state) {
    return state.projectName;
  },

  getUserName(state) {
    return state.userName;
  },

  getAddressNo(state) {
    return state.addressNo;
  },
};

const mutations = {
  setAccessBearerToken(state, data) {
    state.accessBearerToken = data;
  },

  setProjectCode(state, data) {
    state.projectCode = data;
  },

  setProjectName(state, data) {
    state.projectName = data;
  },

  setUserName(state, data) {
    state.userName = data;
  },

  setAddressNo(state, data) {
    state.addressNo = data;
  },

  destroyAuth(state) {
    state.accessBearerToken = "";
  },
};

const actions = {
  async userLogin(context, credentials) {
    try {
      console.log("Params Login ::", credentials);
      const res = await axios.post(process.env.VUE_APP_SALE_LOGIN, credentials);
      const data = res;
      console.log("Response API Login ::", data);

      if (res.status === 200 && res.data.status) {
        const dataInfo = res.data.data;
        localStorage.setItem("accessBearerToken", dataInfo.token);
        localStorage.setItem("projectCode", dataInfo.project_code);
        localStorage.setItem("projectName", dataInfo.project_name);
        localStorage.setItem("userName", dataInfo.user);
        localStorage.setItem("addressNo", dataInfo.address_no);

        context.commit("setAccessBearerToken", dataInfo.token);
        context.commit("setProjectCode", dataInfo.project_code);
        context.commit("setProjectName", dataInfo.project_name);
        context.commit("setUserName", dataInfo.user);
        context.commit("setAddressNo", dataInfo.address_no);

        return dataInfo;
      } else return false;
    } catch (err) {
      console.error(err);
      return err.response.data;
    }
  },

  async destroyAuth(context) {
    if (context.getters.loggedIn) {
      context.commit("destroyAuth");
      localStorage.clear();
      location.reload();
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="h-28 bg-gray-700">
    <Header v-if="$route.name === 'Login'" />
    <Header v-else />
  </div>
  <!-- <div class="block"> -->
  <router-view class="content-page m-auto pb-10 w-4/5 sm:w-2/3 md:max-w-xl" />
  <!-- </div> -->
  <div class="h-10 block bg-gray-700"></div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  name: "App",
  components: {
    Header,
  },
  data() {
    return {};
  },
  async created() {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}

.content-page {
  min-height: calc(100vh - 9.5rem);
}
</style>
